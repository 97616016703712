import React from "react";
import { Container, Col } from "react-bootstrap";
import {
  missionBanner, tiContent, topBorder,
  img, contentTd, imgContent, olavFounders,
  missionContainer, signatureImg, richText
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";
import Richtext from "components/rich-text";

const OurMissionBanner = ( { data }  ) => {

  const { founders, heroImage, signature, text } = data;

  return (
    <div className={missionBanner}>
      <Container className={missionContainer}>
        <div className={tiContent}>
          <Col xl="5">
            <div className={contentTd}>
              <Richtext blocks={text} className={richText} />
              <p className={topBorder}></p>
              <img className={signatureImg} src={getImagePath(signature)} alt={signature?.alt}></img>
            </div>
          </Col>
          <Col xl="7" className={imgContent}>
            <img className={img} src={getImagePath(heroImage)} alt={heroImage?.alt}/>
          </Col>
        </div>
      </Container>
      <p className={olavFounders}>{founders}</p>
    </div>
  );
};

export default OurMissionBanner;
