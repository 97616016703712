// extracted by mini-css-extract-plugin
export var missionBanner = "style-mod--mission-banner--2HuoV";
export var olavFounders = "style-mod--olav-founders--3-ovC";
export var tiContent = "style-mod--ti-content--1NK1v";
export var imgContent = "style-mod--img-content--173f-";
export var contentTd = "style-mod--content-td--289et";
export var founders = "style-mod--founders--mabT4";
export var richText = "style-mod--rich-text--1VwLN";
export var title = "style-mod--title--5dDlP";
export var signatureImg = "style-mod--signature-img--2pv21";
export var description = "style-mod--description--3_zsk";
export var tiBtn = "style-mod--ti-btn--lEWBe";
export var topBorder = "style-mod--top-border--24pR_";
export var img = "style-mod--img--3Ok-G";
export var missionContainer = "style-mod--mission-container--148Cs";